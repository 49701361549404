import Vue from 'vue'

//-- Restor de Código .vue
import VueRouter from 'vue-router'
import SVC00000 from '@/igs-vistas/SVC00000.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SVC00000',
    component: SVC00000
  },
  {
    path: '/00801',
    name: 'SVC00801',
    component: () => import('@/igs-vistas/SVC00801.vue')
  },
  {
    path: '/11000',
    name: 'SVC11000',
    component: () => import('@/igs-vistas/SVC11000.vue')
  },
  {
    path: '/21000',
    name: 'SVC21000',
    component: () => import('@/igs-vistas/SVC21000.vue')
  },
  {
    path: '/22000',
    name: 'SVC22000',
    component: () => import('@/igs-vistas/SVC22000.vue')
  },
  {
    path: '/31000',
    name: 'SVC31000',
    component: () => import('@/igs-vistas/SVC31000.vue')
  },
  {
    path: '/32000',
    name: 'SVC32000',
    component: () => import('@/igs-vistas/SVC32000.vue')
  },
  {
    path: '/41000',
    name: 'SVC41000',
    component: () => import('@/igs-vistas/SVC41000.vue')
  },
  {
    path: '/51000',
    name: 'SVC51000',
    component: () => import('@/igs-vistas/SVC51000.vue')
  },
  {
    path: '/52000',
    name: 'SVC52000',
    component: () => import('@/igs-vistas/SVC52000.vue')
  },
  {
    path: '/91000',
    name: 'SVC9100',
    component: () => import('@/igs-vistas/SVC91000.vue')
  }
]

const igs_routes = new VueRouter({
  routes,
  mode: 'abstract'
  //mode: 'abstract',
  //base: process.env.BASE_URL
})

export default igs_routes
