<!--
#########################################################################################
######    Top Navigation Controller TNC10000  :  Contenido Parte Superior          ######
#########################################################################################
-->
<template>
    <div id="igs-head">
      <!--
      <pre>
        Sesión : {{ $store.state.strIDSesion }},
        Width: {{ $store.state.strScreenWidth }},
        Height: {{ $store.state.strScreenHeight }}      
        Distribuidor: {{ $store.state.strDistribuidor }}      
        Descripción Distribuidor: {{ $store.state.strDescripcionDistribuidor }}      
        Distribuidores Seleccionables : {{$store.state.strListaDistribuidoresSeleccionables}}
        URL Mapa : {{$store.state.strDistribuidorUrlMapa}}
      </pre>
      -->
      <!--
      <pre>
         URL Optimizador : {{$store.state.strDistribuidorUrlOptimizador}}        
      </pre>
      -->
    </div>
</template>



<!--
##################################
###########  Código  #############
##################################
-->
<script>
import igs_mixin from '@/igs-mixins/igs_mixin'

export default {
  mixins: [ igs_mixin ],

  //------------------------ Variables Locales  ---------------------------
  data () {
    return {
    }
  },

  //--------------------- Propiedades Calculadas  ------------------------
  computed : {
  },

  methods: {
  },


  mounted() {
  }
}
</script>


<!--
##################################
###########  Estilo  #############
##################################
-->
<style>
</style>