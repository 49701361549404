<template>
  <div id="app">
    
    <!-- Espacio para Notifiaciones Snotify -->
    <vue-snotify></vue-snotify>

    <!-- Vista Actual -->
    <router-view />

  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss">
</style>
