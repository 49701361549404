//---------------------------------------------------
//-- Mixins (Rutinas y Código de Acceso Global)
//---------------------------------------------------

import { SnotifyPosition, SnotifyStyle }     from 'vue-snotify';

//-- Comunicación HTTP, FTP, ...
const axios = require("axios");

export default {


  //--------------------- Variables GLOBALES  ---------------------------
  data () {
      return {

        HtmlEntitiesMap : {
          "'": "&apos;",
          "<": "&lt;",
          ">": "&gt;",
          " ": "&nbsp;",
          "¡": "&iexcl;",
          "¢": "&cent;",
          "£": "&pound;",
          "¤": "&curren;",
          "¥": "&yen;",
          "¦": "&brvbar;",
          "§": "&sect;",
          "¨": "&uml;",
          "©": "&copy;",
          "ª": "&ordf;",
          "«": "&laquo;",
          "¬": "&not;",
          "®": "&reg;",
          "¯": "&macr;",
          "°": "&deg;",
          "±": "&plusmn;",
          "²": "&sup2;",
          "³": "&sup3;",
          "´": "&acute;",
          "µ": "&micro;",
          "¶": "&para;",
          "·": "&middot;",
          "¸": "&cedil;",
          "¹": "&sup1;",
          "º": "&ordm;",
          "»": "&raquo;",
          "¼": "&frac14;",
          "½": "&frac12;",
          "¾": "&frac34;",
          "¿": "&iquest;",
          "À": "&Agrave;",
          "Á": "&Aacute;",
          "Â": "&Acirc;",
          "Ã": "&Atilde;",
          "Ä": "&Auml;",
          "Å": "&Aring;",
          "Æ": "&AElig;",
          "Ç": "&Ccedil;",
          "È": "&Egrave;",
          "É": "&Eacute;",
          "Ê": "&Ecirc;",
          "Ë": "&Euml;",
          "Ì": "&Igrave;",
          "Í": "&Iacute;",
          "Î": "&Icirc;",
          "Ï": "&Iuml;",
          "Ð": "&ETH;",
          "Ñ": "&Ntilde;",
          "Ò": "&Ograve;",
          "Ó": "&Oacute;",
          "Ô": "&Ocirc;",
          "Õ": "&Otilde;",
          "Ö": "&Ouml;",
          "×": "&times;",
          "Ø": "&Oslash;",
          "Ù": "&Ugrave;",
          "Ú": "&Uacute;",
          "Û": "&Ucirc;",
          "Ü": "&Uuml;",
          "Ý": "&Yacute;",
          "Þ": "&THORN;",
          "ß": "&szlig;",
          "à": "&agrave;",
          "á": "&aacute;",
          "â": "&acirc;",
          "ã": "&atilde;",
          "ä": "&auml;",
          "å": "&aring;",
          "æ": "&aelig;",
          "ç": "&ccedil;",
          "è": "&egrave;",
          "é": "&eacute;",
          "ê": "&ecirc;",
          "ë": "&euml;",
          "ì": "&igrave;",
          "í": "&iacute;",
          "î": "&icirc;",
          "ï": "&iuml;",
          "ð": "&eth;",
          "ñ": "&ntilde;",
          "ò": "&ograve;",
          "ó": "&oacute;",
          "ô": "&ocirc;",
          "õ": "&otilde;",
          "ö": "&ouml;",
          "÷": "&divide;",
          "ø": "&oslash;",
          "ù": "&ugrave;",
          "ú": "&uacute;",
          "û": "&ucirc;",
          "ü": "&uuml;",
          "ý": "&yacute;",
          "þ": "&thorn;",
          "ÿ": "&yuml;",
          "Œ": "&OElig;",
          "œ": "&oelig;",
          "Š": "&Scaron;",
          "š": "&scaron;",
          "Ÿ": "&Yuml;",
          "ƒ": "&fnof;",
          "ˆ": "&circ;",
          "˜": "&tilde;",
          "Α": "&Alpha;",
          "Β": "&Beta;",
          "Γ": "&Gamma;",
          "Δ": "&Delta;",
          "Ε": "&Epsilon;",
          "Ζ": "&Zeta;",
          "Η": "&Eta;",
          "Θ": "&Theta;",
          "Ι": "&Iota;",
          "Κ": "&Kappa;",
          "Λ": "&Lambda;",
          "Μ": "&Mu;",
          "Ν": "&Nu;",
          "Ξ": "&Xi;",
          "Ο": "&Omicron;",
          "Π": "&Pi;",
          "Ρ": "&Rho;",
          "Σ": "&Sigma;",
          "Τ": "&Tau;",
          "Υ": "&Upsilon;",
          "Φ": "&Phi;",
          "Χ": "&Chi;",
          "Ψ": "&Psi;",
          "Ω": "&Omega;",
          "α": "&alpha;",
          "β": "&beta;",
          "γ": "&gamma;",
          "δ": "&delta;",
          "ε": "&epsilon;",
          "ζ": "&zeta;",
          "η": "&eta;",
          "θ": "&theta;",
          "ι": "&iota;",
          "κ": "&kappa;",
          "λ": "&lambda;",
          "μ": "&mu;",
          "ν": "&nu;",
          "ξ": "&xi;",
          "ο": "&omicron;",
          "π": "&pi;",
          "ρ": "&rho;",
          "ς": "&sigmaf;",
          "σ": "&sigma;",
          "τ": "&tau;",
          "υ": "&upsilon;",
          "φ": "&phi;",
          "χ": "&chi;",
          "ψ": "&psi;",
          "ω": "&omega;",
          "ϑ": "&thetasym;",
          "ϒ": "&Upsih;",
          "ϖ": "&piv;",
          "–": "&ndash;",
          "—": "&mdash;",
          "‘": "&lsquo;",
          "’": "&rsquo;",
          "‚": "&sbquo;",
          "“": "&ldquo;",
          "”": "&rdquo;",
          "„": "&bdquo;",
          "†": "&dagger;",
          "‡": "&Dagger;",
          "•": "&bull;",
          "…": "&hellip;",
          "‰": "&permil;",
          "′": "&prime;",
          "″": "&Prime;",
          "‹": "&lsaquo;",
          "›": "&rsaquo;",
          "‾": "&oline;",
          "⁄": "&frasl;",
          "€": "&euro;",
          "ℑ": "&image;",
          "℘": "&weierp;",
          "ℜ": "&real;",
          "™": "&trade;",
          "ℵ": "&alefsym;",
          "←": "&larr;",
          "↑": "&uarr;",
          "→": "&rarr;",
          "↓": "&darr;",
          "↔": "&harr;",
          "↵": "&crarr;",
          "⇐": "&lArr;",
          "⇑": "&UArr;",
          "⇒": "&rArr;",
          "⇓": "&dArr;",
          "⇔": "&hArr;",
          "∀": "&forall;",
          "∂": "&part;",
          "∃": "&exist;",
          "∅": "&empty;",
          "∇": "&nabla;",
          "∈": "&isin;",
          "∉": "&notin;",
          "∋": "&ni;",
          "∏": "&prod;",
          "∑": "&sum;",
          "−": "&minus;",
          "∗": "&lowast;",
          "√": "&radic;",
          "∝": "&prop;",
          "∞": "&infin;",
          "∠": "&ang;",
          "∧": "&and;",
          "∨": "&or;",
          "∩": "&cap;",
          "∪": "&cup;",
          "∫": "&int;",
          "∴": "&there4;",
          "∼": "&sim;",
          "≅": "&cong;",
          "≈": "&asymp;",
          "≠": "&ne;",
          "≡": "&equiv;",
          "≤": "&le;",
          "≥": "&ge;",
          "⊂": "&sub;",
          "⊃": "&sup;",
          "⊄": "&nsub;",
          "⊆": "&sube;",
          "⊇": "&supe;",
          "⊕": "&oplus;",
          "⊗": "&otimes;",
          "⊥": "&perp;",
          "⋅": "&sdot;",
          "⌈": "&lceil;",
          "⌉": "&rceil;",
          "⌊": "&lfloor;",
          "⌋": "&rfloor;",
          "⟨": "&lang;",
          "⟩": "&rang;",
          "◊": "&loz;",
          "♠": "&spades;",
          "♣": "&clubs;",
          "♥": "&hearts;",
          "♦": "&diams;"
      }

     }          
        
    },



  //----------------- Variables Calculadas GLOBALES  --------------------
  computed: {

    //-- Retornar el Valor del ANCHO de Pantala
    f99AppScreenWidth() {
      //console.log('-- f99AppScreenWidth --'+this.$appScreenWidth);
      return this.$appScreenWidth;
    },

    //-- Retornar el Valor del ALTO de Pantalla
    f99AppScreenHeight() {
      //console.log('-- f99AppScreenHeight --'+this.$appScreenHeight);
      return this.$appScreenHeight;
    }

  },



  //--------------------- Observadores GLOBALES -------------------------
  watch: {
  },



  //----------------------- Métodos GLOBALES  ---------------------------
  methods: {

      //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
      //:::  fNNxxxxxxx  - Modelo de Nomenclatura a aplicar
      //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
      //:::
      //:::      (f) = Función        (SÍ retorna un resultado)
      //:::      (p) = Procedimiento  (NO retorna resultados)
      //:::
      //:::      (NN) = Tipo de Función/Procedimiento
      //:::       60  - Procesos de caracter GENERAL
      //:::       70  - Tratamiento de FECHAS
      //:::       90  - Mostrar Contenido en Pantalla
      //:::       97  - Lectura/Escritura Datos sobre URL
      //:::       99  - Mensajes Interactivos (Alertas, Preguntas, ...)
      //:::
      //:::      (xxxxxx) = Nombre de Función/Procedimiento
      //:::
      //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

      
      //----------------------------------------------------------
      //--- Rutinas Generales al Inicio de cada Vista
      //----------------------------------------------------------
      p10Inicio() {

        //-- Si no hay sesión : Página Inicial
        if ( this.$store.state.strIDSesion == "") {
            this.p99AnuncioIM1('4','7','Atención', 'Sesión CADUCADA<br/>Debe identificarse de nuevo',10);
            this.$router.push('/');
        }

        //-- TODO : Si la sesión no es de 20 caracteres, o los 2 últimos no son "cm" : Sesión Corrupta
  

      },

      //----------------------------------------------------------
      //--- Conversión AAAAMMDD en DD-MM-AAAA
      //----------------------------------------------------------
      f70AMD2fDMA(fecha){
        var ano       = fecha.substr(0,4);
        var mes       = fecha.substr(4,2);
        var dia       = fecha.substr(6,2);
  
        return dia + global.lnkDateSeparator + mes + global.lnkDateSeparator + ano;
      },

      //----------------------------------------------------------
      //--- Conversión AAAAMMDD en DD-MM-AA
      //----------------------------------------------------------
      f70AMD2sDMA(fecha){
        var ano       = fecha.substr(2,2);
        var mes       = fecha.substr(4,2);
        var dia       = fecha.substr(6,2);
  
        return dia + global.lnkDateSeparator + mes + global.lnkDateSeparator + ano;
      },

      //----------------------------------------------------------
      //--- Conversión HHMMSS en HH:MM:SS
      //----------------------------------------------------------
      f70HMS2fHMS(tiempo){
        var hora      = tiempo.substr(0,2);
        var minuto    = tiempo.substr(2,2);
        var segundo   = tiempo.substr(4,2);
  
        //console.log('Tiempo : ['+tiempo+']');
        //console.log(' Hora  : ['+hora+'] Minuto : ['+minuto+'] Segundo : ['+segundo+']');

        //-- Evaluar si se debe representar los SEGUNDOS (o no)
        let txtSegundos = global.lnkTimeSeparator + segundo;
        if (segundo == '') { txtSegundos = ''; }

        //-- Retornar el resultado COMPLETO
        return hora + global.lnkTimeSeparator + minuto + txtSegundos;
      },


      //----------------------------------------------------------
      //--- Convertir Segundos a HH:MM:SS
      //----------------------------------------------------------
      f70Segundos2HHMMSS(secs)  {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60
 
        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")
      },


      //----------------------------------------------------------
      //--- Ofuscar Texto (Encode)
      //----------------------------------------------------------
      f75OfuscarTexto(texto) {
        var base64 = require('base-64');

        //-- Fase I
        var resultado = base64.encode(texto);

        //-- Fase II
        resultado = base64.encode(resultado);

        //-- Fase III
        resultado = base64.encode(resultado);

        //-- Fase IV
        resultado = base64.encode(resultado);

        //-- Devolver texto Ofuscado
        return resultado;
      },

      //----------------------------------------------------------
      //--- Desofuscar Texto (Decode)
      //----------------------------------------------------------
      f75DesofuscarTexto(texto) {
        var base64 = require('base-64');

        //-- Fase I
        var resultado = base64.decode(texto);

        //-- Fase II
        resultado = base64.decode(resultado);

        //-- Fase III
        resultado = base64.decode(resultado);

        //-- Fase IV
        resultado = base64.decode(resultado);

        //-- Devolver texto Ofuscado
        return resultado;
      },



      //----------------------------------------------------------
      //--- Decodificar HTML Entities
      //----------------------------------------------------------
      f79DecodeHTML(texto) {

        //-- Casos especiales
        if ((typeof texto == 'undefined') || (texto == '')) {
          return '';
        }

        //-- Como norma general
        var entityMap = this.HtmlEntitiesMap;
        for (var key in entityMap) {
            var entity = entityMap[key];
            var regex = new RegExp(entity, 'g');
            texto = texto.replace(regex, key);
        }

        texto = texto.replace(/&quot;/g, '"');
        texto = texto.replace(/&amp;/g, '&');

        return texto;
      },
      
      //----------------------------------------------------------
      //--- Codificar HTML Entities
      //----------------------------------------------------------
      f79EncodeHTML(texto) {
        //-- Casos especiales
        if ((typeof texto == 'undefined') || (texto == '')) {
          return '';
        }
  
        //-- Como norma general
        var entityMap = this.HtmlEntitiesMap;
        texto = texto.replace(/&/g, '&amp;');
        texto = texto.replace(/"/g, '&quot;');

        for (var key in entityMap) {
            var entity = entityMap[key];
            var regex = new RegExp(key, 'g');
            texto = texto.replace(regex, entity);
        }

        return texto;
      },    

      //------------------------------------------------------------------------------------
      //--- Determinar si el PUNTO (Latitud, Longitud) se encuentra DENTRO del POLÍGONO
      //------------------------------------------------------------------------------------
      f88PuntoEnPoligono(point, vs) {
        var x = point.lat, y = point.lng;
        
        var inside = false;
        for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            var xi = vs[i].lat, yi = vs[i].lng;
            var xj = vs[j].lat, yj = vs[j].lng;
            
            var intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        
        //console.log('-- f88PuntoEnPoligono --');
        //console.log(point);
        //console.log(vs);
        //console.log('f88PuntoEnPoligono : '+inside);
        
        return inside;
      },

      //---------------------------------------------------------------
      //--- Complementar [numero_espacios] por la izquierda y derecha
      //---------------------------------------------------------------
      f91Pad(texto, numero_espacios) {
      
        //-- Verificar que se trata de un campo en formato "texto" (si no fuese así -> corregir)
        if (typeof texto == 'undefined') { texto = ""; }
        if (typeof texto == 'number')    { texto = texto.toString(); }

        //-- Dado que [numero_espacios] es opcional : revisar, y si viene sin cubrir -> asumir 0
        numero_espacios = numero_espacios || 0;

        //-- La concatenación pudiera contener algún fragmento "undefined"
        var n = texto.search("undefined");
        if (n !== -1) { texto = '...'; }

        //-- Convertir valor HTTP (%xy) en (caracter standard) (ejemplo : %3d -> '=')
        texto = unescape(texto);

        //-- Complementar con espacios
        return " ".repeat(numero_espacios) + texto + " ".repeat(numero_espacios);
      },

      //-------------------------------------------------------------------------------------------------
      //--- Importe con Formato ENTERO (y si la parte decimal es DISTINTA DE CERO --> CON 2 Decimales)
      //-------------------------------------------------------------------------------------------------
      f91DecimalEntero(value) {
        //-- Si no se recibe un número devolvemos [valor vacío]
        if (isNaN(value)) { return ""; }

        //-- Determinar si precisa decimales (o no)
        let val  = Math.round(value);
        if (val != value) {  val = (value/1).toFixed(2).replace('.', ','); }

        //-- Maquetar
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      //-------------------------------------------------------------
      //--- Importe Numérico con 2 Decimales de Precisión (SIEMPRE)
      //-------------------------------------------------------------
      f91Decimal(value) {
        //-- Si no se recibe un número devolvemos [valor vacío]
        if (isNaN(value))       { return "";  }
        if (!isFinite(value))   { return "-"; }

        //-- Resto de Casos
        let val = (value/1).toFixed(2).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      //----------------------------------------------------------
      //--- Valor Numérico con Formato ENTERO
      //----------------------------------------------------------
      f91Entero(value) {
        //-- Si no se recibe un número devolvemos [valor vacío]
        if (isNaN(value))       { return "";  }
        if (!isFinite(value))   { return "-"; }

        //-- Resto de Casos
        let val  = Math.round(value);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      //----------------------------------------------------------
      //--- Porcentaje con Formato
      //----------------------------------------------------------
      f91Porcentaje(value) {
        //-- Si no se recibe un número devolvemos [valor vacío]
        if (isNaN(value))       { return "";  }
        if (!isFinite(value))   { return "-"; }

        //-- Resto de Casos
        let val = (value/1).toFixed(2).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
      
      //--------------------------------------------------------------
      //--- Símbolo de la Moneda en la que está expresado el Importe
      //--------------------------------------------------------------
      f91Moneda(value) {
        //-- Si no se recibe un número devolvemos [valor vacío]
        if (isNaN(value)) { return ""; }

        //-- Resto de Casos
        return " €";
      },

      //--------------------------------------------------------------
      //--- Convertir campo Fecha en Formato (aaaammdd) a Date
      //--------------------------------------------------------------
      f92AMD2Date(fecha) {
        //console.log('f92AMD2Date = '+fecha);
        var date = new Date(parseInt(fecha.substr(0,4)), parseInt(fecha.substr(4,2))-1 , parseInt(fecha.substr(6,2)) );
        //console.log('f92AMD2Date : '+date);
        return   date;
      },

      //-----------------------------------------------------------------------------------
      //--- Obtener Índice del DÍA DE LA SEMANA a partir de Fecha en Formato (aaaammdd)
      //--- Resultado : (0)-Lunes ... (6)-Domingo
      //-----------------------------------------------------------------------------------
      f92AMD2DiaSemana(fecha) {
        var date = new Date(parseInt(fecha.substr(0,4)), parseInt(fecha.substr(4,2))-1 , parseInt(fecha.substr(6,2)) );
        let diaSemana = date.getDay();

        if (diaSemana == 0) {
          diaSemana = 6;
        } else {
          diaSemana = diaSemana - 1;
        }

        return diaSemana;
      },
      
      //--------------------------------------------------------------
      //--- Convertir campo (Date) a Fecha en Formato (aaaammdd)
      //--------------------------------------------------------------
      f92Date2AMD(date) {
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();

        return   y + (m < 10 ? '0' : '') + m + (d < 10 ? '0' : '') + d;
      },


      //--------------------------------------------------------------
      //--- Convertir campo (Date) a Fecha en Formato (dd-mm-aaaa)
      //--------------------------------------------------------------
      f92Date2DMA(date) {
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        return (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y;
      },

      //--------------------------------------------------------------
      //--- Convertir campo (Date) a Hora en Formato (HH:MM)
      //--------------------------------------------------------------
      f92Date2HM(date) {
        const h = date.getHours();
        const m = date.getMinutes();
        return (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m;
      },

      //--------------------------------------------------------------
      //--- Convertir campo (Date) a Hora en Formato (HH:MM:SS)
      //--------------------------------------------------------------
      f92Date2HMS(date) {
        const h = date.getHours();
        const m = date.getMinutes();
        const s = date.getSeconds();
        return (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m + ':' + (s < 10 ? '0' : '') + s;
      },


      //--------------------------------------------------------------
      //--- Convertir campo (Date) a (DTZ)
      //--------------------------------------------------------------
      f92Date2DTZ(date) {
        /*
        //-- Convertir a UTC
        var valor_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 
                                 date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

        //-- Convertir a tipo (Date)                                 
        var date_utc = new Date(valor_utc);
                                          
        //-- Descomponer                                 
        const d   = date_utc.getDate();
        const m   = date_utc.getMonth() + 1;
        const y   = date_utc.getFullYear();

        const hor = date_utc.getHours();
        const min = date_utc.getMinutes();
        const seg = date_utc.getSeconds();

        //-- Retornar valor completo
        return y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d + 'T' + (hor < 10 ? '0' : '') + hor + ':' + (min < 10 ? '0' : '') + min + ':' + (seg < 10 ? '0' : '') + seg + 'Z';
        */

        return date.toISOString();
      },

      //----------------------------------------------------------------
      //--- Convertir campo (DTZ) (aaaa-mm-ddThh:mm:ss) a (D-M-A H:M:S)
      //----------------------------------------------------------------
      f92DTZ2DMAHMS(utcDate) {
        var date = new Date(utcDate);
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        const hor = date.getHours();
        const min = date.getMinutes();
        const seg = date.getSeconds();

        return (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y + ' ' + (hor < 10 ? '0' : '') + hor + ':' + (min < 10 ? '0' : '') + min + ':' + (seg < 10 ? '0' : '') + seg;
      },


      //----------------------------------------------------------
      //--- Convertir Milisegundos en HH:MM:SS
      //----------------------------------------------------------
      f93MS2HMS(milliseconds) {
          let seconds = Math.floor(milliseconds / 1000);
          let minutes = Math.floor(seconds / 60);
          let hours = Math.floor(minutes / 60);
        
          seconds = seconds % 60;
          minutes = minutes % 60;
        
          // 👇️ If you don't want to roll hours over, e.g. 24 to 00
          // 👇️ comment (or remove) the line below
          // commenting next line gets you `24:00:00` instead of `00:00:00`
          // or `36:15:31` instead of `12:15:31`, etc.
          //hours = hours % 24;
        
          return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
      },

      //----------------------------------------------------------
      //--- Componer la URL con la cual reclamar los Datos
      //----------------------------------------------------------
      f97GetURL(tabla, metodo) {

        //-- Retornar la URL a utilizar
        var URL = global.lnkServerAppURL + "s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&t=" + tabla + "&m=" + metodo;

        //-- Informar Tabla y Método solicitado (y la URL completa)
        console.info("Reclamando Datos ('"+tabla+"','"+metodo+"') ==> "+URL);

        //-- Devolver el resultado obtenido
        return URL;
      },

      //---------------------------------------------------------------------------------
      //-- Reclamar Ubicación Dispositivos y Mostrar sobre el Mapa
      //---------------------------------------------------------------------------------
      p98t001m501() {
        console.log('p98t001m501');

        //-- Llamada al Servidor
        var URL = 'https://map1.imaps.es/im1?t=001&m=501&u=VjFSS1YyVnNiRmxUYTBab1YwVktOVmxxU210bFZteFlUVWhXWVZkRk1Eaz0=&p=VkZaU1JtVlZNWEZoZWxaT1VrVkZPUT09&x='+encodeURIComponent(this.$store.state.strDistribuidorGrupoDispositivos);
        console.log('-- Lista de Dispositivos --');
        console.log(URL);
        axios.get(URL, {responseEncoding: 'utf-8'})
        .then(respuesta => {
              console.log('-- Datos Recibidos --');

              var datos = respuesta.data;

              var flg = global.lnkGetDataError;
              if (datos.flg) {  flg = datos.flg; }

              //-- Actualizar Resultados
              if (flg == global.lnkGetDataOk) {

                //-- Actualizar Store
                this.$store.commit('p10StrListaDispositivos', datos.t001m501);

              } else {
                if (datos.msg) { 

                  //-- Mostrar el Error al Usuario
                  this.p99AnuncioIM1('2','ERROR',this.f79DecodeHTML(datos.msg),'',null); 

                  //-- (E90) Sesion No Encontrada -> Pantalla de Login
                  if (datos.msg.search("(E90)") !== -1) { 
                    this.$store.commit('p10StrAbandonarSesion', true); 
                    this.$store.commit('p10StrUsuarioAutenticado', false); 
                  }

                } else {
                  this.p99AnuncioIM1('2','ERROR','(E001M501) Error Desconocido','',null); 
                }
              }

              this.$store.commit('p10StrRecibiendoDatos', false);

        })
        .catch(error => {
            console.log('** ERROR al Intentar Recibir Datos **');
            console.log(error.message);

            this.$store.commit('p10StrRecibiendoDatos', false);

            this.p99AnuncioIM1('2','ERROR',error.message,'',null); 
        });

      },

      //-------------------------------------------------------------------
      //-- Lectura Parámetros de Configuración iMaps
      //-------------------------------------------------------------------
      p98t004m003() {
          
        //-- Comunicar con el Servidor del ERP para obtener los Parámetros de Configuración
        var URL = this.f97GetURL('004', '003');
        console.log('-- URL --');
        console.log(URL);
        
        //-- Solicitud de Datos al Servidor 
        axios.get(URL, {responseEncoding: 'utf-8'}).then(respuesta => {

          //-- Extraer la respuesta recibida
          var datos = respuesta.data;
          var flg = global.lnkGetDataError;
          if (datos.flg) {  flg = datos.flg; }

          //-- Actualizar Resultados
          if (flg == global.lnkGetDataOk) {
            //console.log('¡¡¡¡¡¡ Exito !!!!!!');

            //-- Actualizar Valores
            this.$store.commit('p10StrParadaSegundosFactorArticulo',      datos.t004m003.z011);
            this.$store.commit('p10StrParadaSegundosFactorCliente',       datos.t004m003.z012);

            this.$store.commit('p10StrParadaSegundosCriterioArticulos',   datos.t004m003.z021);

            this.$store.commit('p10StrRepartoTramosHorarios',             datos.t004m003.z030);
            
            this.$store.commit('p10StrDocumentoAtributosMostrables',      datos.t004m003.z041);
            console.log('-- z041 --');
            console.dir(datos.t004m003.z041);

          } else {

              //console.dir(datos);

              if (datos.msg) { 
                this.p99AnuncioIM1('2','7','ERROR', this.f79DecodeHTML(datos.msg),-1);
              } else {
                this.p99AnuncioIM1('2','7','ERROR', '(E004003) Error Desconocido',-1);
              }

          }

        })
        .catch(error => {

          //console.dir(error);
          
          //-- Desactivar los Indicadores de Actividad
          this.$store.commit('p10StrRecibiendoDatos', false);

          //-- Mostrar el Mensaje de Error oportuno
          this.p99AnuncioIM1('2','7','ERROR', '[E004003] ' + error.message,-1);
        });

      },


      //--------------------------------------------------------------------------------------
      //-- Procesar contenido JSON (transformando Funciones en modo Texto a Funciones Reales)
      //--------------------------------------------------------------------------------------
      f99JSONFuncional(texto) {

        var jsonTransformed = JSON.parse(texto, function (key, value) {

            //-- Crear FUNCION real, a partir del valor "texto"
            if (value && (typeof value === 'string') && value.indexOf("function") === 0) {
              var jsFunc = new Function('return ' + value)();
              return jsFunc;
            }
      
            //-- Resto de casos
            return value;
        });
          
        return jsonTransformed;

      },

      //--------------------------------------------------------------------------------------
      //-- Eliminar cualquier caracter especial de control
      //--------------------------------------------------------------------------------------
      f99TextoLimpio(texto) {
        //--------------------------------------------------------------------------------------
        // \r    = CR
        // \n    = LF
        // x0B   = VT
        // x0C   = FF
        // x5E   = ^
        // u0085 = LF
        // u2028 = Line      Separator
        // u2029 = Paragraph Separator
        //--------------------------------------------------------------------------------------

        // eslint-disable-next-line no-control-regex
        var textoLimpio = texto.replace(/[\r\n\x0B\x0C\x5E\u0085\u2028\u2029]+/g," ");
        return textoLimpio;
      },

      
      //----------------------------------------------------------
      //--- Mostrar mensaje en ventana reducida
      //--- y esperar hasta que se pulse (Ok)
      //----------------------------------------------------------
      /*
      p99MensajeError(mensaje) {
        
        //-- Eliminar cualquier caracter especial de control
        var mensajeLimpio = mensaje.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g," ");

        //-- Dejar evidencia del error
        console.error("### ERROR ### ("+ mensajeLimpio +")");

        //-- Mostrar la alerta al usuario
        alert({
            title: global.lnkAppName,
            message: mensaje,
            okButtonText: "OK"
          }).then(() => {
            console.info("***(e01)*** Mensaje de ERROR Leído por el Usuario : ("+ mensajeLimpio +")");
          });

      },
      */
 

      //----------------------------------------------------------
      //--- Mensajes Dinámicos
      //----------------------------------------------------------
      // eslint-disable-next-line no-unused-vars
      p99AnuncioIM1(tipoAnuncio, ubicacion, titulo, subtitulo, segundos) {
        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        //==== Tipos de Anuncios ====       =================================== Ejemplos de Uso ===============================
        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        // (1) - Éxito                      this.p99AnuncioIM1('1','1','¡ Éxito !', 'La tarea se ha realizado con éxito',0);
        // (2) - Error                      this.p99AnuncioIM1('2','1','ERROR', 'La tarea NO ha podido ser finalizada',3);
        // (3) - Aviso                      this.p99AnuncioIM1('3','1','Aviso', 'No se ha indicado la descripción de la tarea',5);
        // (4) - Sugerencia                 this.p99AnuncioIM1('4','1','Sugerencia', 'Indique 2 ó 3 valores',10);
        // (5) - Información                this.p99AnuncioIM1('5','1','Información', 'Si compra 2 cajas más, podrá recibir 1 Botella GRATIS',8);
        //---------------------------------------------------------------------------------------------------------------------------------------------------------

        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        //========== Tipos de Ubicación =============                            =================================== Ejemplos de Uso ===============================
        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        // (1) - Izquierda - Arriba
        // (2) - Izquierda - Centro
        // (3) - Izquierda - Abajo
        //
        // (4) - Centro - Arriba
        // (5) - Centro - Centro
        // (6) - Centro - Abajo
        //
        // (7) - Derecha - Arriba
        // (8) - Derecha - Centro
        // (9) - Derecha - Abajo
        //
        // (Otro Valor) = VxD = (7)-Derecha - Arriba
        //---------------------------------------------------------------------------------------------------------------------------------------------------------
        
        //-- Determinar la Ubicación
        var wrkUbicacion = ubicacion;
        if ((wrkUbicacion < '1') || (wrkUbicacion > '9')) { wrkUbicacion = '7'; }

        //....

        var attUbicacion = null;

        switch (wrkUbicacion) {

          case '1':
            attUbicacion = SnotifyPosition.leftTop;
            break;

          case '2':
            attUbicacion = SnotifyPosition.leftCenter;
            break;

          case '3':
            attUbicacion = SnotifyPosition.leftBottom;
            break;

          case '4':
            attUbicacion = SnotifyPosition.centerTop;
            break;

          case '5':
            attUbicacion = SnotifyPosition.centerCenter;
            break;

          case '6':
            attUbicacion = SnotifyPosition.centerBottom;
            break;

          case '7':
            attUbicacion = SnotifyPosition.rightTop;
            break;

          case '8':
            attUbicacion = SnotifyPosition.rightCenter;
            break;

          case '9':
            attUbicacion = SnotifyPosition.rightBottom;
            break;

          default:
            attUbicacion = SnotifyPosition.rightTop;
            break;                                                                                         
        }

        //-- Determinar el Tipo de Anuncio
        var wrkTipoAnuncio = tipoAnuncio;
        if ((wrkTipoAnuncio < '1') || (wrkTipoAnuncio > '5')) { wrkTipoAnuncio = '1'; }

        //....

        var attTipoAnuncio = null;

        switch (wrkTipoAnuncio) {

          case '1':
            attTipoAnuncio = SnotifyStyle.success;
            break;

          case '2':
            attTipoAnuncio = SnotifyStyle.error;
            break;
  
          case '3':
            attTipoAnuncio = SnotifyStyle.warning;
            break;
    
          case '4':
            attTipoAnuncio = SnotifyStyle.simple;
            break;
      
          case '5':
            attTipoAnuncio = SnotifyStyle.info;
            break;

          default :
            attTipoAnuncio = SnotifyStyle.simple;
            break;
        }

        //----------------------------------------------------------------------
        //-- Revisar la Duración
        //----------------------------------------------------------------------
        //   -1            : Valor Temporal Predeterminado
        //    0             : Permanente (hasta que el usuario hace "click")
        //    1 ... 999999 : Temporal (auto-destruir tras N segundos)
        //----------------------------------------------------------------------
        if (segundos < 0)   { segundos = 20; }
        if (segundos > 60)  { segundos = 60; }

        //---------------------------------
        //-- Mostrar el Anuncio
        //---------------------------------
        /*
        this.$snotify.create({
          title: titulo,
          body: subtitulo,
          config: {
            position: attUbicacion,
            type: attTipoAnuncio,
            timeout: segundos * 1000,
            closeOnClick: true,
          }
        });
        */

        this.$snotify.html(`<div class="snotifyToast__title"><b>` + titulo + `</b></div>
                            <div class="snotifyToast__body">`+subtitulo+`</div> `,
                            {
                              position: attUbicacion,
                              type: attTipoAnuncio,
                              timeout: segundos * 1000,
                              showProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                             });

      },


      //----------------------------------------------------------------
      //--- Obtener Ruta Absoluta de la Imagen a mostrar (formato PNG)
      //----------------------------------------------------------------
      f99ImageName(imagen) {
        return global.lnkServerImgURL + imagen + ".png";
      }, 

      
      //----------------------------------------------------------------
      //--- Obtener Ruta Absoluta de la Imagen a mostrar (formato GIF)
      //----------------------------------------------------------------
      f99ImageNameGIF(imagen) {
        return global.lnkServerImgURL + imagen + ".gif";
      }, 



/*
//==========================================================================================================================
//----------   Código VERIFICADO, pendiente de aplicar (cuando resulte necesario)
//==========================================================================================================================

    //------------------------------------------------------------------------------------------
    //--  Mensajes (en modo Post-it)
    //------------------------------------------------------------------------------------------
    lp90PruebaAnuncios01() {
        console.log('*** lp90PruebaAnuncios01 ***');
        //this.$snotify.success('Example body content');
        
        this.$snotify.success('La tarea ha finalizado con ÉXITO', 'Enhorabuena', {timeout: 0});
        this.$snotify.error('No hay contacto con el servidor. ¿ Tienes acceso a Internet ?', 'Error');
        this.$snotify.warning('Sólo quedan 10 unidades', 'Aviso', {timeout: 0});
        this.$snotify.info('Último acceso realizado hace 43 días', 'Información', {timeout: 0});
        this.$snotify.simple('Indica tu dirección de correo', 'Sugerencia', {timeout: 0});
    },

    //------------------------------------------------------------------------------------------
    //--  Mensajes (en modo Post-it)
    //------------------------------------------------------------------------------------------
    lp90PruebaAnuncios02() {

        this.$snotify.prompt('¿ Cuántos dedos tienes en la mano ?', 'Pregunta', {
          timeout: 0,
          placeholder: 'Respuesta'
        });

        // eslint-disable-next-line no-unused-vars
        this.$snotify.async('Descargando contenidos ....', 'Sincronizando', () => new Promise((resolve, reject) => {
            setTimeout(() => resolve({
            title: '¡ Éxito !',
            body: 'Los datos necesarios han sido actualizados correctamente',
            config: {
                closeOnClick: true
            }
            }), 6000);
        }));

        this.$snotify.confirm('¿ Estás seguro ?', 'Atención', {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
            //Yes
            {text: 'Sí', action: () => console.log('Respuesta : SÍ'), bold: false},
            //No
            {text: 'No', action: () => console.log('Respuesta : NO')},
            //Later
            {text: 'Más tarde', action: (toast) => {console.log('Respuesta : MÁS TARDE'); this.$snotify.remove(toast.id); } },
            //Close
            {text: 'Cerrar', action: (toast) => {console.log('Respuesta : CERRAR'); this.$snotify.remove(toast.id); }, bold: true},
            ]
        });

      },

      //------------------------------------------------------------------------------------------
      //--  Mensajes (en modo Post-it)
      //------------------------------------------------------------------------------------------
      lp90PruebaAnuncios03() {

          this.$snotify.html(`<div class="snotifyToast__title"><b>Título Mensaje HTML</b></div>
                              <div class="snotifyToast__body"><i>Mensaje</i> <b>HTML</b> <u>contenido dinámico</u></div> `, {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
          });

          //---------

          const yesAction = (toast) => {
            if (!toast.value.match('1234')) {
              console.log('Respuesta incorrecta');
              toast.valid = false;
              return false;
            } else {
              console.log('Respuesta CORRECTA');
              toast.valid = true; // default value
              this.$snotify.remove(toast.id)
            }
          }

          const noAction = (toast) => {
            console.log('Respuesta : NO');
            this.$snotify.remove(toast.id) // default
          }

          this.$snotify.prompt('¿ Tienes más de 18 años ?', 'Pregunta', {
            buttons: [
              {text: 'Sí', action: yesAction, bold: true },
              {text: 'No', action: noAction },
            ],
            placeholder: 'Dinos tu respuesta'
          });

          //---------------------------

          this.$snotify.simple('Mensaje con Icono Asociado', 'Ejemplo', {
            timeout: 8000,
            showProgressBar: false,
            closeOnClick: true,
            icon: global.lnkServerImgURL + 'ih1-stock-azul.png'
          });
      },

    //------------------------------------------------------------------------------------------
    //--  Mensajes (en modo Ventana)
    //------------------------------------------------------------------------------------------
    lp90PruebaMensajes(tipo) {
        console.log('*** lp90PruebaMensajes ***');

        if (tipo == '1') {
            this.p99mensajeIM1('1','¡ Éxito !', 'La tarea se ha realizado con éxito','',null);
        }

        if (tipo == '2') {
            this.p99mensajeIM1('2','ERROR', 'La tarea NO ha podido ser finalizada','',null);
        }

        if (tipo == '3') {
            this.p99mensajeIM1('3','ATENCIÓN', 'No se ha indicado la descripción de la tarea','',null);
        }

        if (tipo == '4') {
            this.p99mensajeIM1('4','ADVERTENCIA', 'Tarea NO permitida','',null);
        }
        
        if (tipo == '5') {
            this.p99mensajeIM1('5','Atención', 'Si compra 2 cajas más, podrá recibir 1 Botella GRATIS','',null);
        }
         
        if (tipo == '6') {
            this.p99mensajeIM1('6','Pregunta', '¿ Quieres agregar estos 10 artículos a la Cesta ?','No','Sí');
        }

        if (tipo == '7') {
            this.p99mensajeIM1('7','Pregunta', 'Indique el Nombre para esta CESTA','Nombre de la Cesta','Confirmar');
        }

    },


      //------------------------------------------------------------------------------------------
      //--  Mensajes (en modo Post-it)
      //------------------------------------------------------------------------------------------
      lp90PruebaAnuncios04() {
        this.p99AnuncioIM1('1','1','Éxito','La tarea ha finalizado correctamente.',10)
        this.p99AnuncioIM1('2','3','Error','Error <b>E27</b><br/>No se ha indicado el <i>USUARIO</i>',30)
        this.p99AnuncioIM1('3','5','Aviso','La tarea ha finalizado correctamente.',20)
        this.p99AnuncioIM1('4','9','Advertencia','La tarea ha finalizado correctamente.',15)
        this.p99AnuncioIM1('5','7','Información','La tarea ha finalizado correctamente.',40)
      }


*/
      
    }
  }
  